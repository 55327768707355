<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Junio 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- filtros de busqueda y boton para agregar un usuario -->
        <div class="d-flex pt-3">
            <v-text-field class="filtros me-2" outlined dense hide-details label="Usuario" 
                v-model="buscadorUsuario" @input="listarUsuarios()">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Nombre"
                v-model="buscadorNombreDeUsuario" @input="listarUsuarios()">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Bodega" @paste.prevent
                v-model="buscadorBodega" @keypress="event => validarLetra(event, false)" @input="listarUsuarios()">
            </v-text-field>

            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Usuarios_Crear)" left color="success">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on"
                        @mousedown.prevent="dialog = true">
                        <v-icon> add </v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>
        </div>

        <!-- dialogo para crear un usuario -->
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="33rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Crear</span>
                </v-card-title>

                <!-- formulario para crear un usuario -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>

                            <div class="d-flex">
                                 <!-- Campo para ingresar el Nombre del usuario -->
                                <validation-provider class="campos" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearUsuario.nombreUsuario" label="Nombre" 
                                         required dense outlined :error-messages="errors">
                                    </v-text-field>
                                </validation-provider>

                                <!-- Campo para ingresar el Apellido del usuario -->
                                <validation-provider class="campos  ms-2" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearUsuario.apellidoUsuario" label="Apellido" 
                                         required dense outlined :error-messages="errors">
                                    </v-text-field>
                                </validation-provider>
                            </div>

                            <div class="d-flex">
                                <!-- Campo para ingresar el usuario -->
                                <validation-provider class="campos" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearUsuario.usuario" label="Usuario" required dense outlined
                                        :error-messages="validarUsuario || errors" @keypress="verificarLetras($event)" @input="verificarUsuario"
                                        @paste.prevent>
                                    </v-text-field>
                                </validation-provider>

                                <!-- Campo para el código de la bodega -->
                                <validation-provider class="campos ms-2" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field outlined v-model="crearUsuario.bodega" dense @paste.prevent
                                        :error-messages="validarBodega || errors" label="Código de bodega"
                                        @keypress="event => validarLetra(event, false)" @input="verificarExistencia">
                                    </v-text-field>
                                </validation-provider>
                            </div>

                            <div class="d-flex">
                                <!-- Campo para el nombre de la bodega -->
                                <v-text-field class="campos" outlined v-model="crearUsuario.nombreBodega" dense
                                        label="Nombre de bodega" readonly>
                                </v-text-field>
                            </div>

                             <div class="d-flex">

                                <!-- Campo para ingresar la contraseña del usuario -->
                                <div class="d-flex align-items-center">
                                    <validation-provider class="campos" v-slot="{ errors }" :rules="crearUsuario.tipoContraseña ? { required: true } : { required: true, passwordRules: true  }">
                                        <v-text-field v-model="crearUsuario.contraseñaUsuario" label="Contraseña" @paste.prevent inputmode="verbatim"
                                            :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'visibility' : 'visibility_off'" @keydown.space.prevent
                                            @click:append="show1 = !show1" required dense outlined :error-messages="errors" style="width: 13.1rem;">
                                        </v-text-field>
                                    </validation-provider>
                                    <div class="d-flex align-items-center"
                                        style="width: 1.8rem; padding-bottom: 1.6rem; padding-left: 0.13rem;">
                                        <v-tooltip right color="blue darken-3">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                                            help
                                            </v-icon>
                                        </template>
                                        <span>La contraseña no temporal debe tener <br>
                                            mínimo 8 caracteres, una mayúscula,  <br>
                                            un número y un caracter especial. <br>
                                            <em>Ejemplo: Herinc0*</em></span>
                                        </v-tooltip>
                                    </div>
                                </div>

                                 <!-- Campo para elegir el tipo de contraseña -->
                                <div style="width: 100%; padding-top: 0.6rem;" class="d-flex ms-2 toggle-button-cover">
                                    <div class="temporal">
                                        <span>Temporal: </span>
                                    </div>
                                    <div class="button radio ms-2" style="margin-top: -0.9rem;" id="boton-3">
                                        <input type="checkbox" class="checkbox" 
                                            v-model="crearUsuario.tipoContraseña">
                                        <div class="si"></div>
                                        <div class="no"></div>
                                    </div>
                                    <div class="d-flex"
                                        style="width: 2.6rem; margin-top: 0.5rem; padding-right: 5.2rem;">
                                        <v-tooltip right color="blue darken-3">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                                                help
                                                </v-icon>
                                            </template>
                                            <span>La contraseña temporal pedirá cambio <br>
                                                de contraseña al iniciar sesión.</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </div>

                            <!-- Botones de cerrar y guardar que se activan cuando se abre el dialogo para crear un usuario-->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="clean()">
                                    CERRAR
                                </v-btn>
                                <v-btn depressed :disabled="!errorValidacion || invalid" color="success" text @click="añadirUsuario()">
                                    GUARDAR
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo para editar un usuario -->
        <v-dialog v-model="dialogEditar" persistent transition="dialog-bottom-transition" max-width="33rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Editar: {{crearUsuario.usuario}}</span>
                </v-card-title>

                <!-- formulario para crear y editar un usuario -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>

                           
                            <div class="d-flex">
                                 <!-- Campo para ingresar el Nombre del usuario -->
                                <validation-provider class="campos" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearUsuario.nombreUsuario" label="Nombre" 
                                        required dense outlined :error-messages="errors">
                                    </v-text-field>
                                </validation-provider>

                                <!-- Campo para ingresar el Apellido del usuario -->
                                <validation-provider class="campos  ms-2" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearUsuario.apellidoUsuario" label="Apellido" 
                                         required dense outlined :error-messages="errors">
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            
                            <div class="d-flex">
                                <!-- Campo para el código de la bodega -->
                                <validation-provider class="campos" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field outlined v-model="crearUsuario.bodega" dense @paste.prevent
                                        :error-messages="validarBodega || errors" label="Código de bodega"
                                        @keypress="event => validarLetra(event, false)" @input="verificarExistencia">
                                    </v-text-field>
                                </validation-provider>

                                <!-- Campo para el nombre de la bodega -->
                                <v-text-field class="campos ms-2" outlined v-model="crearUsuario.nombreBodega" dense
                                        label="Nombre de bodega" readonly>
                                </v-text-field>
                            </div>

                            <!-- Botones de cerrar y guardar que se activan cuando se abre el dialogo para crear un usuario-->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="clean()">
                                    CERRAR
                                </v-btn>
                                <v-btn depressed :disabled="!errorValidacion || invalid" color="success" text @click="actualizarUsuario()">
                                    ACTUALIZAR
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo para cambiar una bodega relacionada con un usuario -->
        <v-dialog v-model="dialogEditarBodega" persistent transition="dialog-bottom-transition" max-width="33rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Cambiar bodega: {{crearUsuario.usuario}}</span>
                </v-card-title>

                <!-- formulario para crear y editar un usuario -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>

                            <div class="d-flex">
                                <!-- Campo para el código de la bodega -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field outlined v-model="crearUsuario.bodega" dense style="width: 8.2rem;"
                                        :error-messages="validarBodega || errors" label="Código de bodega" @paste.prevent
                                        @keypress="event => validarLetra(event, false)" @input="verificarExistencia">
                                    </v-text-field>
                                </validation-provider>

                                <!-- Campo para el nombre de la bodega -->
                                <v-text-field class="ms-2" outlined v-model="crearUsuario.nombreBodega" dense
                                        label="Nombre de bodega" readonly>
                                </v-text-field>
                            </div>

                            <!-- Botones de cerrar y guardar que se activan cuando se abre el dialogo para cambiar una bodega-->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="clean()">
                                    CERRAR
                                </v-btn>
                                <v-btn depressed :disabled="!errorValidacion || botonEdicionDesactivado || invalid" color="success" text @click="actualizarBodega()">
                                    ACTUALIZAR
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo para restablecer la contraseña del usuario -->
        <v-dialog v-model="dialogContraseña" persistent transition="dialog-bottom-transition" max-width="20rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Restablecer contraseña</span>
                </v-card-title>

                <!-- formulario para restablecer la contraseña del usuario -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>

                            <div>
                                <!-- Campo para ingresar el usuario -->
                                <v-text-field v-model="crearUsuario.usuario" label="Usuario" required dense outlined
                                    :disabled="disabled == 1">
                                </v-text-field>
                            </div>

                            <div class="d-flex align-items-center">
                                <!-- Campo para ingresar la contraseña del usuario -->
                                <validation-provider class="campos" v-slot="{ errors }" :rules="crearUsuario.tipoContraseña ? { required: true } : { required: true, passwordRules: true }">
                                    <v-text-field v-model="crearUsuario.contraseñaUsuario" label="Contraseña"
                                        :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'visibility' : 'visibility_off'"
                                        @click:append="show1 = !show1" required dense outlined :error-messages="errors" @keydown.space.prevent>
                                    </v-text-field>
                                </validation-provider>
                                <div class="d-flex align-items-center"
                                    style="width: 1.8rem; padding-bottom: 1.6rem; padding-left: 0.13rem;">
                                    <v-tooltip right color="blue darken-3">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                                        help
                                        </v-icon>
                                    </template>
                                    <span>La contraseña no temporal debe tener <br>
                                        mínimo 8 caracteres, una mayúscula,  <br>
                                        un número y un caracter especial. <br>
                                        <em>Ejemplo: Herinc0*</em></span>
                                    </v-tooltip>
                                </div>
                            </div>

                            <!-- Campo para elegir el tipo de contraseña -->
                            <div class="d-flex toggle-button-cover">
                                <div class="temporal">
                                    <span>Temporal: </span>
                                </div>
                                <div class="button radio ms-2" id="boton-3">
                                    <input type="checkbox" class="checkbox" 
                                        v-model="crearUsuario.tipoContraseña">
                                    <div class="si"></div>
                                    <div class="no"></div>
                                </div>
                                <div class="d-flex"
                                    style="width: 2.6rem; padding-bottom: 0.3rem; padding-left: 0.16rem;">
                                    <v-tooltip right color="blue darken-3">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                                            help
                                            </v-icon>
                                        </template>
                                        <span>La contraseña temporal pedirá cambio <br>
                                             de contraseña al iniciar sesión.</span>
                                    </v-tooltip>
                                </div>
                            </div>

                            <!-- Botones de cerrar y guardar el cambio de contraseña-->
                            <div class="d-flex mt-4 justify-end">
                                <v-btn color="error" text @click="clean()">
                                    CERRAR
                                </v-btn>
                                <v-btn depressed :disabled="invalid" color="success" text @click="restablecerContraseña()">
                                    GUARDAR
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra los registros de la tabla usuarios de postgreSQL -->
        <v-data-table :loading="loading" fixed-header :headers="headersUsuarios" :items="listaDeUsuarios"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
            class="elevation mt-4" height="66vh" :footer-props="footerPropsTabla">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="(item) in items" :key="item.id">
                        <td>
                            {{ item.usuario }}
                        </td>
                        <td>
                            {{ item.nombre }}
                        </td>
                        <td>
                            {{ item.bodega.bodegasId.codigoBodega }} - {{ item.bodega.nombreBodega }}
                        </td>
                        <td>
                            <span v-if="item.estadoUsuario == 'true'"><v-icon color="success"> check_circle </v-icon> Activo</span>
                            <span v-if="item.estadoUsuario == 'false'"><v-icon color="error"> cancel </v-icon> Inactivo</span>
                        </td>
                        <td v-if="userRoles.includes(roles.Configuraciones_Administrativo_Usuarios_Editar)" class="text-center">

                            <!-- Campo que permite abrir un dialog para cambiar la información de un usuario -->
                            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Usuarios_Editar_Usuario)" bottom 
                                :color="item.estadoUsuario === 'true' ? 'orange' : 'gray'">
                                <template v-slot:activator="{ on, attrs }">
                                    <button v-bind="attrs" v-on="on" @mousedown.prevent="editarUsuario(item)" :disabled="item.estadoUsuario !== 'true'">
                                        <v-icon :color="item.estadoUsuario === 'true' ? 'orange' : 'gray'"> border_color </v-icon>
                                    </button>
                                </template>
                                <span v-if="item.estadoUsuario === 'true'">Editar usuario</span>
                                <span v-else>Active primero el usuario</span>
                            </v-tooltip>

                            <!-- Campo que permite abrir un dialog para cambiar la bodega relacionada a un usuario -->
                            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Usuarios_Editar_Bodega)" bottom 
                                :color="item.estadoUsuario === 'true' ? '#0d47a1' : 'gray'">
                                <template v-slot:activator="{ on, attrs }">
                                    <button class="ms-2" v-bind="attrs" v-on="on" @mousedown.prevent="abrirDialogBodega(item)" :disabled="item.estadoUsuario !== 'true'">
                                        <v-icon :color="item.estadoUsuario === 'true' ? '#0d47a1' : 'gray'"> cached </v-icon>
                                    </button>
                                </template>
                                <span v-if="item.estadoUsuario === 'true'">Cambiar bodega</span>
                                <span v-else>Active primero el usuario</span>
                            </v-tooltip>

                            <!-- Campo que permite abrir un dialog para cambiar el estado de un usuario -->
                            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Usuarios_Editar_Estado)" bottom :color="item.estadoUsuario === 'true' ? 'error' : 'success'">
                                <template v-slot:activator="{ on, attrs }">
                                    <button class="ms-2" v-bind="attrs" v-on="on" @mousedown.prevent="abrirDialogoEstado(item)">
                                        <v-icon :color="item.estadoUsuario === 'true' ? 'error' : 'success'">{{ item.estadoUsuario === 'true' ? 'person_add_disabled' : 'how_to_reg'}}</v-icon>
                                    </button>
                                    </template>
                                <span>{{ item.estadoUsuario === 'true' ? 'Inactivar usuario' : 'Activar usuario' }}</span>
                            </v-tooltip>

                            <!-- Campo que permite abrir un dialog para cambiar la contraseña de un usuario -->
                            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Usuarios_Editar_Password)" bottom 
                                :color="item.estadoUsuario === 'true' ? '#6d7072' : 'gray'">
                                <template v-slot:activator="{ on, attrs }">
                                    <button class="ms-2" v-bind="attrs" v-on="on" @mousedown.prevent="abrirDialogoContraseña(item)" :disabled="item.estadoUsuario !== 'true'">
                                        <v-icon :color="item.estadoUsuario === 'true' ? '#6d7072' : 'gray'"> vpn_key </v-icon>
                                    </button>
                                </template>
                                <span v-if="item.estadoUsuario === 'true'">Cambiar contraseña</span>
                                <span v-else>Active primero el usuario</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- Campo para activar o inactivar el estado de un usuario -->
        <v-dialog v-model="dialogoCambiarEstado" persistent transition="dialog-bottom-transition" max-width="27rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6" >
                        {{ mensajeEstado }} {{ registroSeleccionado.usuario }}?
                    </span>
                </v-card-title>
                <v-card-text>
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <div class="d-flex justify-end" style="padding-top: 1.3rem">
                            <v-btn text color="error" @click="dialogoCambiarEstado = false">No</v-btn>
                            <v-btn text depressed color="success" @click="cambiarEstado()" :disabled="invalid">Si</v-btn>
                        </div>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationProvider, ValidationObserver, } from 'vee-validate';
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role.js";
import { required, email } from 'vee-validate/dist/rules';

// validaciones con vee-validate
extend('required', {
    ...required,
    message: 'Este campo es requerido',
});

extend('validEmail', {
    ...email,
    message: 'La dirección de correo debe ser válida', 
});

extend('passwordRules', {
  validate: (value) => {
    // Verifica si la contraseña tiene al menos 8 caracteres
    const hasMinLength = value.length >= 8;
    const hasUppercase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\]:;<>,.?~\\-]/.test(value);

    // La validación es exitosa si la contraseña es válida o está vacía
    return (hasMinLength && hasUppercase && hasNumber && hasSpecialCharacter) || !value;
  },
  message: 'No cumple con los requisitos',
});

export default {
    name: "CrearUsuarios",
    components: {
        ValidationProvider, 
        ValidationObserver,
    },

    data() {
        return {
            userRoles: {},
            roles: Role,
            usuarioLogueado: '',
            buscadorUsuario: '',
            buscadorNombreDeUsuario: '',
            buscadorBodega: '',
            dialog: false,
            dialogEditar: false,
            dialogEditarBodega: false,
            dialogContraseña: false,
            crearUsuario: {
                usuario: '',
                nombreUsuario: '',
                apellidoUsuario: '',
                contraseñaUsuario: '',
                tipoContraseña: false,
                bodega: [],
                nombreBodega: '',
            },
            enabled: '',
            eliminado: false,
            show1: false,
            registroSeleccionado: {},
            dialogoCambiarEstado: false,
            mensajeEstado: '',
            validarBodega: '',
            validarUsuario: '',
            errorValidacion: false,
            bodegaOriginal: null,
            headersUsuarios: [],
            listaDeUsuarios: [],
            listarUnaBodega: [],
            pagina: 1,
            totalPaginas: 0,
            itemsPorPagina: 10,
            loading: false,
            footerPropsTabla: {
                'items-per-page-options': [10, 15, 20, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado, se ejecuta el método listarUsuarios() y define los encabezados para la tabla.
    created() {
        this.userRoles = this.auth.roles;
        this.listarUsuarios();
        this.errorValidacion = true;

        this.headersUsuarios = [
            { text: "USUARIO", align: "left", width: "12%", sortable: false },
            { text: "NOMBRE", align: "left", width: "37%", sortable: false },
            { text: "BODEGA", align: "left", width: "43%", sortable: false },
            { text: "ESTADO", align: "center", width: "6.9rem", sortable: false },
        ];
        if (this.userRoles.includes(this.roles.Configuraciones_Administrativo_Usuarios_Editar)) {
            this.headersUsuarios.push({
                text: "ACCIONES",
                align: "center",
                width: "12rem",
                sortable: false,
            });
        }
    },

    // Se mapean los estados del store a propiedades computadas
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
        
        /**
         * Se parcea el valor actual del campo Código de bodega y se establece para no permitir 
         * la activación del botón actualizar cuando el valor es el mismo.
         */ 
        botonEdicionDesactivado() {
            const bodegaActual = parseInt(this.crearUsuario.bodega);
            return !this.bodegaOriginal || bodegaActual === this.bodegaOriginal;
        },
    },

    // Se asigna el valor del username del usuario autenticado a usuarioLogueado en mayúsculas
    mounted() {
        this.usuarioLogueado = this.auth.username.toUpperCase();
    },

    // Se vigila el cambio en las variables y se ejecuta el método listarUsuarios()
    watch: {
        pagina: function () {
            this.listarUsuarios();
        },
        itemsPorPagina: function () {
            this.pagina = 1;
            this.listarUsuarios();
        },
        'buscadorUsuario': function () {
            this.pagina = 1;
            this.listarUsuarios();
        },
        'buscadorNombreDeUsuario': function () {
            this.pagina = 1;
            this.listarUsuarios();
        },
        'buscadorBodega': function () {
            this.pagina = 1;
            this.listarUsuarios();
        },
        // Vaciar el campo del nombre de la bodega
        'crearUsuario.bodega': function(newBodegaValue) {
            if (!newBodegaValue) {
                this.crearUsuario.nombreBodega = ''; 
            }
        },
    },

    methods: {
        // Se mapean las mutaciones del store a métodos locales
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),

        /**
        * Valida la tecla presionada en un evento de entrada.
        * Comprueba si la tecla presionada no es "e", "-", "+" o ",".
        * Si no es ninguna de esas teclas, se verifica si la tecla es un número o el símbolo "$".
        * Si no es un número ni el símbolo "$", se previene la acción por defecto del evento.
        * En caso de que la tecla sea "e", "-", "+" o ",", también se previene la acción por defecto del evento.
        * @param {object} event - El evento de entrada que contiene la tecla presionada.
        */
        validarLetra(event, permiteCero = false) {
            const teclaPresionada = event.key;

            // Verificar si el primer carácter es 0
            if (!permiteCero && teclaPresionada === '0' && event.target.value.length === 0) {
                event.preventDefault();
            }

            // Verificar si se presiona una tecla que no es un número
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },

        /**
        * Valida la tecla presionada en un evento de entrada.
        * Maneja expresión regular para permitir solo letras minúsculas.
        * @param {object} event - El evento de entrada que contiene la tecla presionada.
        */
        verificarLetras(event) {
            const teclaPresionada = event.key;
            const regex = /^[a-z]$/; // Expresión regular para letras minúsculas
            if (!regex.test(teclaPresionada)) {
                event.preventDefault();
            }
        },
        
        /**
        * Obtiene la lista de usuarios mediante una solicitud HTTP GET al servidor.
        * Los usuarios se obtienen con paginación y se filtran según los parámetros especificados.
        * Después de obtener los datos, se formatean ciertos campos y se asignan a la lista de usuarios del componente.
        * Se actualiza el número total de páginas y se registra el resultado en la consola.
        * Además, se controla el estado de carga durante la solicitud.
        */
        listarUsuarios() {
        this.loading = true;
        this.$http
            .get(`msa-administration/usuarios/listar`, {
                params: {
                    page: this.pagina - 1,
                    size: this.itemsPorPagina,
                    usuario: `${this.buscadorUsuario.toLowerCase()}`,
                    nombre: `${this.buscadorNombreDeUsuario.toLowerCase()}`,
                    codigoBodega: this.buscadorBodega,
                    idEmpresa: this.enterprise.code,
                },
            }).then(response => {
                    this.listaDeUsuarios = response.data.content;
                    this.totalPaginas = response.data.totalElements;
                    this.loading = false;
            }).catch(error => {
                console.log(error);
                this.loading = false;
            });
        },

        /**
        * Obtiene la lista de bodega mediante una solicitud HTTP GET al servidor.
        * Después de obtener los datos, se asignan valor nombre a el campo crearUsuario.nombreBodega.
        */
        listarBodega() {
            if (this.crearUsuario.bodega) {
                    this.$http
                        .get(`msa-administration/bodegas/listarBodega`, {
                            params: {
                                idEmpresa: this.enterprise.code,
                                codigoBodega: Number(this.crearUsuario.bodega)
                            }
                        })
                        .then(response => {
                            if (response.data.length === 1) {
                                const bodega = response.data[0];
                                this.crearUsuario.bodega = `${bodega.bodegasId.codigoBodega}`; 
                                this.crearUsuario.nombreBodega =  `${bodega.nombreBodega}`
                            }
                            this.listarUnaBodega = response.data;
                        }).catch(error => {
                            console.log(error);
                        });
            }
        },

        /**
        * Verifica la existencia de una bodega en la tabla 'bodegas' de PostgreSQL.
        * Retorna un mensaje indicando la disponibilidad de la bodega.
        */
        verificarExistencia() {
            this.validarBodega = '';
            this.errorValidacion = false;
            if (this.crearUsuario.bodega) {
                this.$http
                    .get(`msa-administration/bodegas/validarExistencia`, {
                        params: {
                            idEmpresa: this.enterprise.code,
                            codigoBodega: Number(this.crearUsuario.bodega)
                        }
                    })
                    .then(response => {
                        if (response.data === null) {
                            this.validarBodega = "La bodega no existe";
                            this.crearUsuario.nombreBodega = '';
                        } else {
                            this.validarBodega = '';
                            this.errorValidacion = true;
                            this.listarBodega();
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.validarBodega = "Error al verificar la bodega";
                        this.errorValidacion = true;
                    });
            } else {
                this.validarBodega = '';
                this.errorValidacion = false;
            }
        },

        /**
        * Verifica la existencia de un usuario en postgreSQL en la tabla Usuarios.
        * Indica si el usuario ya existe en la base de datos.
        */
        verificarUsuario() {
            this.validarUsuario = '';
            this.errorValidacion = false;
            if (this.crearUsuario.usuario) {
                this.$http
                    .get(`msa-administration/usuarios/validarExistencia?usuario=${this.crearUsuario.usuario}`)
                    .then(response => {
                        if (response.data.statusCodeValue === 400) {
                            this.validarUsuario = "El usuario ya existe";
                        } else {
                            this.validarUsuario = '';
                            this.errorValidacion = true;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.validarUsuario = "Error al verificar el usuario";
                        this.errorValidacion = true;
                    });
            } else {
                this.validarUsuario = '';
                this.errorValidacion = false;
            }
        },

        /**
        * Crea un nuevo usuario en base de datos.
        * Se requiere que los campos obligatorios estén especificados para crear el usuario.
        */
        añadirUsuario() {
            const requiredActions = this.crearUsuario.tipoContraseña === true ? ["UPDATE_PASSWORD"] : [];
            // Realizar la solicitud POST para crear el usuario
            this.$http
            .post("msa-administration/usuarios/crear", {
                idEmpresa: this.enterprise.code,
                username: this.crearUsuario.usuario,
                firstName: this.crearUsuario.nombreUsuario,
                lastName: this.crearUsuario.apellidoUsuario,
                password: this.crearUsuario.contraseñaUsuario,
                enabled: true,
                temporary: this.crearUsuario.tipoContraseña,
                requiredActions: requiredActions,
                usuarioLogueado: this.usuarioLogueado,
                bodegasId: {
                    idEmpresa: this.enterprise.code,
                    codigoBodega: Number(this.crearUsuario.bodega)
                },
                isDeleted: this.eliminado,
            })
            .then(() => {
                // Actualizar la lista de usuarios y restablecer los campos
                this.listarUsuarios();
                this.clean();
            })
            .catch((error) => {
                console.log(error);
            });
        },

        /**
        * Prepara el formulario para editar los datos personales un usuario existente.
        * Copia los datos del usuario seleccionado al formulario de edición y configura los valores correspondientes.
        * @param {Object} item - El registro del usuario que se va a editar.
        */
        editarUsuario(item) {
            this.errorValidacion = true;
           // Dividir el nombre completo en partes
            const nombreCompleto = item.nombre;
            const partesNombre = nombreCompleto ? nombreCompleto.split(' ') : [];
            let nombre = '';
            let apellido = '';
            if (partesNombre.length > 0) {
                nombre = partesNombre[0] ?? '';
                apellido = partesNombre.slice(1).join(' ') ?? '';
            }
            if (partesNombre.length <= 2) {
                nombre = partesNombre[0];
                apellido = partesNombre.slice(1).join(' ');
            } else if (partesNombre.length === 3) {
                nombre = partesNombre[0];
                apellido = partesNombre.slice(1).join(' ');
            }else {
                nombre = partesNombre.slice(0, 2).join(' ');
                apellido = partesNombre.slice(2).join(' ');
            }
            // Copiar los datos del usuario seleccionado al formulario de edición
            this.crearUsuario = {
                usuario: item.usuario,
                nombreUsuario: nombre, 
                apellidoUsuario: apellido,
                bodega: item.bodega.bodegasId.codigoBodega,
                nombreBodega: item.bodega.nombreBodega,
            };
            this.dialogEditar = true;
        },

        /**
        * Actualiza los datos de un usuario en base de datos y en keycloak.
        * Se requiere que los campos obligatorios estén diligenciados para actualizar el usuario.
        */
        actualizarUsuario() {
            // Realizar la solicitud POST para actualizar el usuario
            this.$http
            .put("msa-administration/usuarios/actualizar", {
                idEmpresa: this.enterprise.code,
                username: this.crearUsuario.usuario,
                firstName: this.crearUsuario.nombreUsuario,
                lastName: this.crearUsuario.apellidoUsuario,
                bodegasId: {
                    idEmpresa: this.enterprise.code,
                    codigoBodega: Number(this.crearUsuario.bodega)
                },
                usuarioLogueado: this.usuarioLogueado,
            })
            .then(() => {
                // Actualizar la lista de usuarios y restablecer los campos
                this.listarUsuarios();
                this.clean();
            })
            .catch((error) => {
                console.log(error);
            });
        },

        /**
        * Abre el diálogo para cambiar el estado de un usuario.
        * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
        * Guarda el registro seleccionado en la propiedad 'registroSeleccionado'.
        * Este método se invoca cuando se desea cambiar el estado de un usuario.
        * @param {object} item - El registro seleccionado.
        */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true;
            this.registroSeleccionado = item;
            this.mensajeEstado = this.registroSeleccionado.estadoUsuario == 'true' ? '¿Desea inactivar el usuario ' : '¿Desea activar el usuario ';
            setTimeout(() => {
                this.mensajeEstado = this.registroSeleccionado.estadoUsuario == 'true' ? '¿Desea inactivar el usuario ' : '¿Desea activar el usuario ';
            }, 4000);
        },

        /**
        * Cambia el estado de un usuario.
        * Actualiza el estado del usuario seleccionado ('registroSeleccionado') según la lógica definida.
        * Realiza una solicitud HTTP PUT al servidor para actualizar el estado del usuario.
        * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de usuarios.
        */
        cambiarEstado() {
            this.registroSeleccionado.estadoUsuario = (this.registroSeleccionado.estadoUsuario == 'true') ? 'false' : 'true';
            const eliminar = (this.registroSeleccionado.estadoUsuario == 'true') ? false : true;
            const cambiarEst = {
                username: this.registroSeleccionado.usuario,
                enabled: this.registroSeleccionado.estadoUsuario,
                usuarioLogueado: this.usuarioLogueado,
                isDeleted: eliminar,
            }
            this.$http
                .put(`msa-administration/usuarios/cambiarEstado`, cambiarEst)
                .then(() => {
                    this.dialogoCambiarEstado = false;
                    this.listarUsuarios();
                    this.clean();
                }).catch(error => {
                    console.log(error);
                })
        },

        /**
        * Abre el diálogo para cambiar la contraseña de un usuario.
        * Establece la propiedad 'dialogContraseña' en true para mostrar el diálogo.
        * Guarda el registro seleccionado en la propiedad 'registroSeleccionado'.
        * Este método se invoca cuando se desea restablecer la contraseña de un usuario.
        * @param {object} item - El registro seleccionado.
        */
        abrirDialogoContraseña(item) {
            // Copiar los datos del usuario seleccionado al formulario de edición
            this.crearUsuario = {
                usuario: item.usuario,
                tipoContraseña: false, 
            };
            this.dialogContraseña = true;
            this.disabled = 1;
        },

        /**
        * Restablecer la contraseña de un usuario.
        * Actualiza la contraseña del usuario seleccionado ('registroSeleccionado') según la lógica definida.
        * Realiza una solicitud HTTP PUT al servidor para actualizar la contraseña del usuario.
        * En esta operación se envia el estado del usuario en true.
        * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de usuarios.
        */
        restablecerContraseña() {
            const requiredActions = this.crearUsuario.tipoContraseña === true ? ["UPDATE_PASSWORD"] : [];
            this.$http
                .put("msa-administration/usuarios/cambiarPassword", {
                    username: this.crearUsuario.usuario,
                    password: this.crearUsuario.contraseñaUsuario,
                    requiredActions: requiredActions,
                    enabled: true,
                    temporary: this.crearUsuario.tipoContraseña,
                    usuarioLogueado: this.usuarioLogueado,
                })
                .then(() => {
                    this.dialogContraseña = false;
                    this.listarUsuarios();
                    this.clean();
                }).catch(error => {
                    console.log(error);
                })
        },

        /**
        * Prepara el formulario para editar la bodega que esta registrada con un usuario existente.
        * Copia los datos del usuario seleccionado al formulario de edición y configura los valores correspondientes.
        * @param {Object} item - El registro del usuario que se va a editar.
        */
        abrirDialogBodega(item) {
            // Dividir el nombre completo en partes
            const nombreCompleto = item.nombre;
            const partesNombre = nombreCompleto ? nombreCompleto.split(' ') : [];
            let nombre = '';
            let apellido = '';
            if (partesNombre.length > 0) {
                nombre = partesNombre[0] ?? '';
                apellido = partesNombre.slice(1).join(' ') ?? '';
            }
            if (partesNombre.length <= 2) {
                nombre = partesNombre[0];
                apellido = partesNombre.slice(1).join(' ');
            } else if (partesNombre.length === 3) {
                nombre = partesNombre[0];
                apellido = partesNombre.slice(1).join(' ');
            }else {
                nombre = partesNombre.slice(0, 2).join(' ');
                apellido = partesNombre.slice(2).join(' ');
            }
            // Copiar los datos del usuario seleccionado al formulario de edición
            this.crearUsuario = {
                usuario: item.usuario,
                nombreUsuario: nombre, 
                apellidoUsuario: apellido,
                bodega: item.bodega.bodegasId.codigoBodega,
                nombreBodega: item.bodega.nombreBodega,
            };
            this.bodegaOriginal = item.bodega.bodegasId.codigoBodega;
            this.dialogEditarBodega = true;
        },

        /**
        * Cambia la bodega relacionada a un usuario en base de datos.
        * Se requiere que el campo de la bodega este diligenciado para cambiar la bodega.
        */
        actualizarBodega() {
            // Realizar la solicitud POST para actualizar el usuario
            this.$http
            .put("msa-administration/usuarios/actualizarBodega", {
                idEmpresa: this.enterprise.code,
                username: this.crearUsuario.usuario,
                bodegasId: {
                    idEmpresa: this.enterprise.code,
                    codigoBodega: Number(this.crearUsuario.bodega)
                },
                usuarioLogueado: this.usuarioLogueado,
            })
            .then(() => {
                // Actualizar la lista de usuarios y restablecer los campos
                this.listarUsuarios();
                this.clean();
            })
            .catch((error) => {
                console.log(error);
            });
        },

        /**
        * Restablece los valores de los campos y las variables relacionadas al relacionar un usuario.
        * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
        * Reinicia el estado de edición y activación de campos.
        * Reinicia el estado del componente de observación (refs.observer).
        */
        clean() {
            this.crearUsuario.usuario = '';
            this.crearUsuario.nombreUsuario = '';
            this.crearUsuario.apellidoUsuario = '';
            this.crearUsuario.contraseñaUsuario = '';
            this.crearUsuario.tipoContraseña = false;
            this.crearUsuario.bodega = [];
            this.crearUsuario.nombreBodega = '';
            this.validarBodega = '';
            this.validarUsuario = '';
            this.dialog = false;
            this.dialogEditar = false;
            this.dialogContraseña = false,
            this.dialogEditarBodega = false;
            this.dialogoCambiarEstado = false;
            this.show1 = false;
            this.errorValidacion = false;
            this.tituloDialog = 'Crear usuario';
            this.$refs.observer.reset();
        },
    },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

.fondoDialog {
  background-color: #1867c0;
  color: white;
}

.campos {
    width: 100% !important;
}

.temporal {
    font-size: medium;
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 12rem;
  height: 1.7rem;
  box-sizing: border-box;
}

.button-cover,
.si,
.no {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 60%;
  width: 4.4rem;
  height: 1.9rem;
  margin: -1.3rem auto 0 auto;
  overflow: hidden;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.si {
  z-index: 2;
}

.no {
  width: 100%;
  background-color: #eceaea;
  transition: 0.3s ease all;
  z-index: 1;
}

.button.radio,
.button.radio .no {
  border-radius: 1rem;
}

#boton-3 .si:before {
  content: 'NO';
  position: absolute;
  top: 0.27rem;
  left: 0.3rem;
  width: 1.4rem;
  height: 1.37rem;
  color: #fff;
  font-size: 0.75rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  line-height: 1;
  padding: 0.3rem 0.16rem;
  background-color: #777777;
  border-radius: 1rem;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#boton-3 .checkbox:active + .si:before {
  width: 3rem;
  border-radius: 1rem;
}

#boton-3 .checkbox:checked:active + .si:before {
  margin-left: -1.6rem;
}

#boton-3 .checkbox:checked + .si:before {
  content: 'SI';
  left: 2.6rem;
  background-color: #1867c0;
}

#boton-3 .checkbox:checked ~ .no {
  background-color: #b5ceea;
}
</style>